import Link from 'next/link';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import PageWrapper from '~/components/wrappers/pageWrapper';
import SiteSettings from '~/siteSettings';

const Utag = dynamic(() => import('~/components/utag/utag'), { ssr: false });

const FourOhFour = props => {
  const { sitePrimaryRsid } = props;

  return (
    <>
    <Head>
      <title>Page Not Found | { SiteSettings.siteName }</title>
      <meta name='description' content='Error'/>
    </Head>
    <div className='page-error'>
      <div className='page-error-content'>
        <div>
          <h2>Page Not Found</h2>
          <h1>404</h1>
          <div className='spacer spacer--2'></div>
          <Link href='/'>
            <a className='btn btn--brand'>
              Go To Homepage
            </a>
          </Link>
        </div>
      </div>
      <Utag pageType='404' siteSection='404' data={{ sitePrimaryRsid }} />
    </div>
  </>
  );
}

export default PageWrapper(FourOhFour);
